import 'antd/dist/antd.css';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header } from './components/header';
import { SharePopover } from './components/share_popover';
import { NewChatSession } from './components/new_chat_session';
import { MessageThread } from './components/message_thread';
import { NodeList } from './components/node_list';
import { addOriginNode } from './rpc_logic';
import { store } from './store';

export const App: React.FC = observer(() => {
  useEffect(() => {
    if (!location.hash) {
      return;
    }

    // There is a node ID in the URL hash, we connect to that and continue past
    // the landing page.
    const targetNodeId = location.hash.slice(1).toLowerCase();
    void addOriginNode(targetNodeId);
    store.setChatReady();
  }, []);

  if (!store.isChatReady) {
    return (
      <div>
        <Header />
        <NewChatSession />
      </div>
    );
  }

  const content = store.isMobile ? (
    <FullContent>
      {store.isShowingNodeList ? <NodeList /> : <MessageThread />}
    </FullContent>
  ) : (
    <>
      <LeftContent>
        <NodeList />
      </LeftContent>
      <RightContent>
        <MessageThread />
      </RightContent>
    </>
  );

  return (
    <>
      <Header />
      {content}
      <SharePopover />
    </>
  );
});

const LeftContent = styled.div`
  border-right: 1px solid white;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 36px;
  width: 350px;
`;

const RightContent = styled.div`
  bottom: 0;
  left: 350px;
  position: absolute;
  right: 0;
  top: 36px;
`;

const FullContent = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 36px;
`;
