import { Maglev } from '@wlabs/maglev';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  adjectives,
  animals,
  uniqueNamesGenerator,
} from 'unique-names-generator';
import { wlabs } from './proto_gen';

/**
 * The width is set a little high just because things look a lot better in
 * mobile-mode below about 850.
 */
const DESKTOP_WIDTH_CUTOFF = 850;

export class Store {
  public readonly maglev = new Maglev({
    relay: { url: 'wss://relay.maglev.wlabs.dev' },
    wrtc: { iceServers: [{ urls: 'stun:stun3.l.google.com' }] },
  });
  public readonly humanNodeName = uniqueNamesGenerator({
    dictionaries: [adjectives, animals],
    separator: '-',
  });
  public selfNodeId: string = '';
  public messages: wlabs.examples.Msg_WithDefaultValues[] = [];
  public knownNodesById = new Map<string, NodeInfo>();

  // A few miscellaneous fields used primarily for UI.
  public sharingNodePopover: { id: string; name: string } | null = null;
  public isChatReady = false;
  public isMobile = window.innerWidth <= DESKTOP_WIDTH_CUTOFF;
  public isShowingNodeList = false;

  constructor() {
    makeObservable(this, {
      selfNodeId: observable,
      messages: observable.shallow,
      knownNodesById: observable,
      sharingNodePopover: observable,
      isChatReady: observable,
      isMobile: observable,
      isShowingNodeList: observable,
      updateKnownNode: action,
      saveMessage: action,
      addOriginNodeInfo: action,
      setShareNodeIdPopover: action,
      setChatReady: action,
      setShowNodeList: action,
    });

    // Wait for a self node ID to become available and set that publicly.
    this.maglev.nodes
      .self()
      .then((selfNode) =>
        runInAction(() => (this.selfNodeId = selfNode.nodeId))
      );

    // Also keep isMobile up to date.
    window.addEventListener('resize', () =>
      runInAction(
        () => (this.isMobile = window.innerWidth <= DESKTOP_WIDTH_CUTOFF)
      )
    );
  }

  public updateKnownNode(id: string, nodeInfo: Partial<NodeInfo>) {
    const existing = this.knownNodesById.get(id) || {
      id,
      telegraphClient: wlabs.examples.Telegraph.createClient(
        store.maglev.nodes.get(id)
      ),
    };
    this.knownNodesById.set(id, { ...existing, ...nodeInfo } as NodeInfo);
  }

  public saveMessage(msg: wlabs.examples.Msg_WithDefaultValues) {
    // Use lodash to do a binary search on the array and insert the message at
    // the correct location; sorted by origin timestamp.
    const i = _.sortedIndexBy(this.messages, msg, (m) =>
      m.traces[0].timestamp.toNumber()
    );
    this.messages.splice(i, 0, msg);

    console.log('Msg from', msg.traces[0].nodeId, ':', msg.chatText);
  }

  public addOriginNodeInfo(
    id: string,
    trace: wlabs.examples.Trace_WithDefaultValues,
    telegraphClient: wlabs.examples.TelegraphClient
  ) {
    this.knownNodesById.set(id, {
      id,
      broadcastTo: true,
      name: trace.humanName,
      telegraphClient,
    });
  }

  public setShareNodeIdPopover(nodeId: string | null) {
    if (!nodeId) {
      this.sharingNodePopover = null;
      return;
    }

    this.sharingNodePopover = {
      id: nodeId,
      name:
        nodeId === this.selfNodeId
          ? this.humanNodeName
          : this.knownNodesById.get(nodeId).name,
    };
  }

  public setChatReady() {
    this.isChatReady = true;
  }

  public setShowNodeList() {
    this.isShowingNodeList = !this.isShowingNodeList;
  }
}

export interface NodeInfo {
  id: string;
  broadcastTo: boolean;
  name: string;
  telegraphClient: wlabs.examples.TelegraphClient;
}

export const store: Store = new Store();
