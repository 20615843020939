import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
import { store } from '../store';
import { NodeNameLabel } from './node_name_label';

export const SharePopover: React.FC = observer(() => {
  if (!store.sharingNodePopover) {
    return null;
  }

  const url = location.origin + '#' + store.sharingNodePopover.id;

  return (
    <>
      <BackgroundBlur onClick={() => store.setShareNodeIdPopover(null)} />
      <Popover onClick={(e) => e.stopPropagation()}>
        <PopoverHeader>
          <NodeNameLabel node={store.sharingNodePopover} />
        </PopoverHeader>
        <PopoverContent>
          <QRCode
            size={180}
            value={url}
            style={{
              margin: '10px auto',
            }}
          />
          <a href={url} target="_blank">
            {url}
          </a>
          <div>
            The QR code and URL are the same. They will connect a client
            directly to the <NodeNameLabel node={store.sharingNodePopover} />{' '}
            node and by extension join the mesh chat network through that node.
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
});

const BackgroundBlur = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.68);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Popover = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 365px;
  height: 365px;
  border-radius: 20px;
  padding: 10px 20px;
`;

const PopoverHeader = styled.div`
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 8px;
`;

const PopoverContent = styled.div`
  font-size: 0.8em;
  color: black;
  display: flex;
  flex-direction: column;

  text-align: center;

  a {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  span {
    display: contents;
  }
`;
