import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { NodeInfo, store } from '../store';
import { NodeNameLabel } from './node_name_label';
import { Button } from 'antd';

export const NodeList: React.FC = observer(() => {
  return (
    <Container>
      <SingleNode />
      {[...store.knownNodesById.values()]
        .filter((info) => info.id !== store.selfNodeId)
        .map((info) => (
          <SingleNode key={info.id} info={info} />
        ))}
    </Container>
  );
});

const SingleNode: React.FC<{ info?: NodeInfo }> = (props) => {
  return (
    <SingleNodeContainer
      style={{
        borderBottom: props.info ? '' : '1px solid white',
      }}
    >
      <NodeNameLabel
        node={
          props.info
            ? props.info
            : {
                id: store.selfNodeId,
                name: store.humanNodeName + (props.info ? '' : ' (self)'),
              }
        }
      />
      <Button
        size="small"
        type="ghost"
        onClick={() =>
          store.setShareNodeIdPopover(
            props.info ? props.info.id : store.selfNodeId
          )
        }
      >
        Share
      </Button>
      <Subtext>
        <div>
          pubkey: <i>{props.info ? props.info.id : store.selfNodeId}</i>
        </div>
      </Subtext>
    </SingleNodeContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingleNodeContainer = styled.div`
  position: relative;
  padding: 16px;

  button {
    position: absolute;
    right: 16px;
    color: white;
  }
`;

const Subtext = styled.div`
  font-size: 0.8em;
`;
