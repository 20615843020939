import React from 'react';
import { wlabs } from '../proto_gen';
import { NodeInfo } from '../store';

export interface Props {
  node: Pick<NodeInfo, 'id' | 'name'> | wlabs.examples.Msg_WithDefaultValues;
}

export const NodeNameLabel: React.FC<Props> = (props) => {
  let nodeName = 'UNKNOWN';
  let nodeId = 'UNKNOWN';

  if ('id' in props.node) {
    nodeName = props.node.name;
    nodeId = props.node.id;
  } else {
    nodeName = props.node.traces[0].humanName;
    nodeId = props.node.traces[0].nodeId;
  }

  const color = CHAT_COLORS[nodeId.charCodeAt(0) % CHAT_COLORS.length];

  return <span style={{ fontWeight: 'bold', color: color }}>{nodeName} </span>;
};

export function getNameColor(nodeId: string) {
  return CHAT_COLORS[nodeId.charCodeAt(0) % CHAT_COLORS.length];
}

const CHAT_COLORS = [
  '#00F0FF',
  'darkred',
  'darkgreen',
  'orange',
  'darkblue',
  'darkmagenta',
  'red',
  'green',
  'magenta',
];
