import React from 'react';
import { render } from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { App } from './app';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto Mono;
    color: #ffffff;
    background: #1E1E1E;
    margin: 0;
    height: 100vh;
    overflow: hidden;
  }
`;

render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById('root')
);
