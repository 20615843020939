import { NodeRef } from '@wlabs/maglev';
import Long from 'long';
import { action } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import { wlabs } from './proto_gen';
import { store } from './store';

const seenMsgUuid = new Set<string>();

export async function sendMessageText(chatText: string) {
  const msg = {
    uuid: uuidV4(),
    traces: [makeTrace(await store.maglev.nodes.self())],
    chatText,
  };
  seenMsgUuid.add(msg.uuid);

  for (const [_id, info] of store.knownNodesById) {
    if (info.broadcastTo) {
      void info.telegraphClient.sendMsg({ msg });
    }
  }

  store.saveMessage(msg);
}

export async function addOriginNode(id: string) {
  // Say hello to the origin node, get a reply and add it as an origin.
  const client = wlabs.examples.Telegraph.createClient(
    store.maglev.nodes.get(id)
  );
  const res = await client.hello({
    origin: makeTrace(await store.maglev.nodes.self()),
  });

  store.addOriginNodeInfo(id, res.origin, client);

  for (const msg of res.lastMsgs) {
    store.saveMessage(msg);
  }
}

function makeTrace(selfNode: NodeRef) {
  return {
    nodeId: selfNode.nodeId,
    humanName: store.humanNodeName,
    timestamp: Long.fromNumber(Date.now()),
  };
}

(async () => {
  const selfNode = await store.maglev.nodes.self();

  wlabs.examples.Telegraph.registerHandler(store.maglev, {
    hello: action((req, ctx) => {
      store.updateKnownNode(ctx.peerAuthInfo.nodeId, {
        broadcastTo: true,
        name: req.origin.humanName,
      });
      return {
        origin: makeTrace(selfNode),
        lastMsgs: store.messages.slice(-100),
      };
    }),
    goodbye: action((_req, ctx) => {
      store.updateKnownNode(ctx.peerAuthInfo.nodeId, { broadcastTo: false });
      return {};
    }),
    sendMsg: async (req, ctx) => {
      const msg = req.msg!;

      // Track and ignore messages we've already seen to prevent cycles.
      if (seenMsgUuid.has(msg.uuid!)) {
        return {};
      }
      seenMsgUuid.add(msg.uuid!);

      // Save the message now, before we append the trace with our own node info.
      store.saveMessage(msg);

      // Add ourselves to the message trace, and broadcast the message to all
      // other peers we are tracking.
      msg.traces!.push(makeTrace(selfNode));

      for (const [id, info] of store.knownNodesById) {
        if (id === ctx.peerAuthInfo.nodeId || !info.broadcastTo) {
          // Don't transmit back to the peer that just sent us this message or
          // to a peer we don't have broadcast set to true on.
          continue;
        }

        void info.telegraphClient.sendMsg({ msg });
      }

      for (const trace of msg.traces) {
        store.updateKnownNode(trace.nodeId, { name: trace.humanName });
      }

      return {};
    },
  });
})();
