/**
 * This file is generated by the Maglev CLI. Do not manually edit.
 * @internal
 */
/* eslint-disable */

import { ProtoGenAPI as __ProtoGenAPI } from '@wlabs/maglev';

// Type error here? It means you need to re-generate this file with the same
// version of the Maglev client you're using.
const ApiVersionAssertion: __ProtoGenAPI.ProtoGenAPIVersion = 1;

export namespace wlabs {
  export namespace examples {
    export interface GreetingRequest<TNum = number | Long> {
      origin?: wlabs.examples.Trace<TNum>;
    }

    export interface GreetingRequest_WithDefaultValues {
      origin?: wlabs.examples.Trace_WithDefaultValues;
    }

    export namespace GreetingRequest {
      export const fullName = 'wlabs.examples.GreetingRequest';

      export function verify(obj: GreetingRequest): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(
        obj: GreetingRequest,
        delimitated = false,
      ): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): GreetingRequest_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface GreetingResponse<TNum = number | Long> {
      origin?: wlabs.examples.Trace<TNum>;
      lastMsgs?: wlabs.examples.Msg<TNum>[];
    }

    export interface GreetingResponse_WithDefaultValues {
      origin?: wlabs.examples.Trace_WithDefaultValues;
      lastMsgs: wlabs.examples.Msg_WithDefaultValues[];
    }

    export namespace GreetingResponse {
      export const fullName = 'wlabs.examples.GreetingResponse';

      export function verify(obj: GreetingResponse): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(
        obj: GreetingResponse,
        delimitated = false,
      ): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): GreetingResponse_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface Msg<TNum = number | Long> {
      uuid?: string;
      traces?: wlabs.examples.Trace<TNum>[];
      chatText?: string;
    }

    export interface Msg_WithDefaultValues {
      uuid: string;
      traces: wlabs.examples.Trace_WithDefaultValues[];
      chatText: string;
    }

    export namespace Msg {
      export const fullName = 'wlabs.examples.Msg';

      export function verify(obj: Msg): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(obj: Msg, delimitated = false): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): Msg_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface SendMsgRequest<TNum = number | Long> {
      msg?: wlabs.examples.Msg<TNum>;
    }

    export interface SendMsgRequest_WithDefaultValues {
      msg?: wlabs.examples.Msg_WithDefaultValues;
    }

    export namespace SendMsgRequest {
      export const fullName = 'wlabs.examples.SendMsgRequest';

      export function verify(obj: SendMsgRequest): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(
        obj: SendMsgRequest,
        delimitated = false,
      ): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): SendMsgRequest_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface SendMsgResponse<TNum = number | Long> {}

    export interface SendMsgResponse_WithDefaultValues {}

    export namespace SendMsgResponse {
      export const fullName = 'wlabs.examples.SendMsgResponse';

      export function verify(obj: SendMsgResponse): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(
        obj: SendMsgResponse,
        delimitated = false,
      ): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): SendMsgResponse_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface Trace<TNum = number | Long> {
      nodeId?: string;
      humanName?: string;
      timestamp?: TNum;
    }

    export interface Trace_WithDefaultValues {
      nodeId: string;
      humanName: string;
      timestamp: Long;
    }

    export namespace Trace {
      export const fullName = 'wlabs.examples.Trace';

      export function verify(obj: Trace): string | null {
        return __ProtoGenAPI.injected.verify(fullName, obj);
      }

      export function encode(obj: Trace, delimitated = false): Uint8Array {
        return __ProtoGenAPI.injected.encode(fullName, obj, delimitated);
      }

      export function decode(
        data: Uint8Array,
        delimitated = false,
      ): Trace_WithDefaultValues {
        return __ProtoGenAPI.injected.decode(fullName, data, delimitated);
      }
    }

    export interface TelegraphClient {
      sendMsg: (
        req: wlabs.examples.SendMsgRequest,
      ) => Promise<wlabs.examples.SendMsgResponse_WithDefaultValues>;
      hello: (
        req: wlabs.examples.GreetingRequest,
      ) => Promise<wlabs.examples.GreetingResponse_WithDefaultValues>;
      goodbye: (
        req: wlabs.examples.GreetingRequest,
      ) => Promise<wlabs.examples.GreetingResponse_WithDefaultValues>;
    }

    export interface TelegraphHandler {
      sendMsg: (
        req: wlabs.examples.SendMsgRequest_WithDefaultValues,
        ctx: __ProtoGenAPI.CallContext,
      ) =>
        | Promise<wlabs.examples.SendMsgResponse>
        | wlabs.examples.SendMsgResponse;
      hello: (
        req: wlabs.examples.GreetingRequest_WithDefaultValues,
        ctx: __ProtoGenAPI.CallContext,
      ) =>
        | Promise<wlabs.examples.GreetingResponse>
        | wlabs.examples.GreetingResponse;
      goodbye: (
        req: wlabs.examples.GreetingRequest_WithDefaultValues,
        ctx: __ProtoGenAPI.CallContext,
      ) =>
        | Promise<wlabs.examples.GreetingResponse>
        | wlabs.examples.GreetingResponse;
    }

    export namespace Telegraph {
      export const fullName = 'wlabs.examples.Telegraph';

      export function registerHandler(
        scope: __ProtoGenAPI.ServiceScope,
        handler: Partial<TelegraphHandler>,
      ): () => void {
        return __ProtoGenAPI.injected.registerHandler(fullName, scope, handler);
      }

      export function createClient(
        nodeRef: __ProtoGenAPI.NodeRef,
      ): TelegraphClient {
        return __ProtoGenAPI.injected.createClient(fullName, nodeRef);
      }
    }
  }
}

// This registers the reflection object for runtime use. It's a binary
// (base64 encoded) `protobuf wlabs.maglev.meta.Meta` message.
__ProtoGenAPI.addMetaDefinitionBase64(
  'CokCChsKDndsYWJzLmV4YW1wbGVzEglUZWxlZ3JhcGgSTAoHU2VuZE1zZxIdd2xhYnMuZXhhbXBsZXMuU2VuZE1zZ1JlcXVlc3QaHndsYWJzLmV4YW1wbGVzLlNlbmRNc2dSZXNwb25zZSAAKAASTAoFSGVsbG8SHndsYWJzLmV4YW1wbGVzLkdyZWV0aW5nUmVxdWVzdBofd2xhYnMuZXhhbXBsZXMuR3JlZXRpbmdSZXNwb25zZSAAKAASTgoHR29vZGJ5ZRIed2xhYnMuZXhhbXBsZXMuR3JlZXRpbmdSZXF1ZXN0Gh93bGFicy5leGFtcGxlcy5HcmVldGluZ1Jlc3BvbnNlIAAoABJFCiAKDndsYWJzLmV4YW1wbGVzEg5TZW5kTXNnUmVxdWVzdBIhCgNtc2cQARoSd2xhYnMuZXhhbXBsZXMuTXNnIAEoADgFEiMKIQoOd2xhYnMuZXhhbXBsZXMSD1NlbmRNc2dSZXNwb25zZRJLCiEKDndsYWJzLmV4YW1wbGVzEg9HcmVldGluZ1JlcXVlc3QSJgoGb3JpZ2luEAEaFHdsYWJzLmV4YW1wbGVzLlRyYWNlIAEoADgFEnQKIgoOd2xhYnMuZXhhbXBsZXMSEEdyZWV0aW5nUmVzcG9uc2USJgoGb3JpZ2luEAEaFHdsYWJzLmV4YW1wbGVzLlRyYWNlIAEoADgFEiYKCGxhc3RNc2dzEAIaEndsYWJzLmV4YW1wbGVzLk1zZyABKAE4BRJzChUKDndsYWJzLmV4YW1wbGVzEgNNc2cSFgoEdXVpZBABGgZzdHJpbmcgASgAOAISJgoGdHJhY2VzEAIaFHdsYWJzLmV4YW1wbGVzLlRyYWNlIAEoATgFEhoKCGNoYXRUZXh0EAMaBnN0cmluZyABKAA4AhJtChcKDndsYWJzLmV4YW1wbGVzEgVUcmFjZRIYCgZub2RlSWQQARoGc3RyaW5nIAEoADgCEhsKCWh1bWFuTmFtZRACGgZzdHJpbmcgASgAOAISGwoJdGltZXN0YW1wEAMaBnVpbnQ2NCABKAA4AQ==',
);
