import React from 'react';
import styled from 'styled-components';
import Logo from 'url:../../assets/images/logo.svg';
import MeshIcon from 'url:../../assets/images/mesh_button_icon.svg';
import { Button } from 'antd';
import { store } from '../store';
import { observer } from 'mobx-react-lite';

export const Header: React.FC = observer(() => {
  return (
    <HeaderContainer>
      <img
        style={{
          left: store.isMobile ? '-295px' : '0',
        }}
        src={Logo}
      ></img>
      <div style={{ flex: 1 }} />
      <Button target="_blank" href="https://maglev.wlabs.dev/docs" type="text">
        Docs
      </Button>
      <Button
        target="_blank"
        href="https://gitlab.com/tungstenlabs/maglev/-/tree/main/examples/web/telegraph"
        type="text"
      >
        Source
      </Button>
      {store.isMobile && store.isChatReady && (
        <Button
          type={store.isShowingNodeList ? 'primary' : 'ghost'}
          icon={
            <img
              src={MeshIcon}
              style={{
                height: 23,
                left: 4,
              }}
            />
          }
          onClick={() => store.setShowNodeList()}
        />
      )}
    </HeaderContainer>
  );
});

const HeaderContainer = styled.div`
  border-bottom: 1px solid white;
  width: 100vw;
  height: 36px;
  display: flex;
  padding-right: 60px;

  img {
    position: absolute;
    top: 4px;
  }

  a {
    font-size: 1.5em;
    line-height: 1.9em;
    color: white;
  }
  a:focus {
    color: white;
  }

  a:hover {
    color: #1890ff;
  }

  button {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 1.5em;
    line-height: 1.9em;
  }
`;
