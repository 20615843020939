import { Input, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { sendMessageText } from '../rpc_logic';
import { store } from '../store';
import Carrot from 'url:../../assets/images/carrot_icon.svg';
import { getNameColor, NodeNameLabel } from './node_name_label';

const { TextArea } = Input;

export const MessageThread: React.FC = observer(() => {
  const [msgInput, setMsgInput] = React.useState('');

  return (
    <Container>
      <MessageList>
        {store.messages.map((msg) => (
          <Popover
            key={msg.uuid}
            title="Trace"
            content={
              <>
                {msg.traces.map((trace, i) => (
                  <div key={i}>
                    <TraceDot
                      style={{ background: getNameColor(trace.nodeId) }}
                    />
                    {trace.humanName}
                  </div>
                ))}
              </>
            }
          >
            <Message
              style={
                msg.traces[0].nodeId === store.selfNodeId
                  ? { marginLeft: 'auto' }
                  : { marginRight: 'auto' }
              }
            >
              <NodeNameLabel node={msg} />
              {msg.chatText}
              <MessageFooter>
                Sent{' '}
                <Moment fromNow>
                  {new Date(msg.traces[0].timestamp.toNumber()).toUTCString()}
                </Moment>
              </MessageFooter>
            </Message>
          </Popover>
        ))}
      </MessageList>
      <MessageInput>
        <img src={Carrot} />
        <TextArea
          style={{
            background: 'transparent',
            color: 'white',
          }}
          autoFocus={true}
          bordered={false}
          autoSize={true}
          value={msgInput}
          onChange={(e) => setMsgInput(e.target.value)}
          onPressEnter={(e) => {
            e.preventDefault();
            if (msgInput) {
              sendMessageText(msgInput);
            }
            setMsgInput('');
          }}
        />
      </MessageInput>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: auto;
  padding: 16px 16px 4px 16px;
  width: 100%;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  overflow: auto;
`;

const MessageInput = styled.div`
  display: flex;
  padding-top: 20px;
`;

const Message = styled.div`
  margin-top: 30px;
  max-width: 70%;
`;

const MessageSender = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const MessageFooter = styled.div`
  font-size: 0.75em;
  font-style: italic;
  color: #646464;
  margin-top: 4px;
`;

const TraceDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  float: left;
  margin: 3px 6px 0 0;
`;
