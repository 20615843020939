import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { store } from '../store';

export const NewChatSession: React.FC = observer(() => {
  return (
    <Container>
      <Button
        disabled={!store.selfNodeId}
        type="primary"
        onClick={() => store.setChatReady()}
      >
        {store.selfNodeId ? 'New Chat Session' : 'Connecting to Infra'}
      </Button>
      <p>
        To join an existing chat session, use a share-link (or scan the QR code)
        for any node in an existing session.
      </p>
    </Container>
  );
});

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 20vw;
  right: 20vw;
  transform: translate(0%, -50%);
  text-align: center;

  p {
    margin-top: 50px;
  }
`;
